import NewswireTable from "../components/NewswireTable";
import { Tag, Button, Space, notification, Select } from "antd";
import { useEffect, useState } from "react";
import { TransformedColumns } from "../utils/columnHeader";
import NewswireController from "../controller/NewswireController";
import { EditOutlined } from "@ant-design/icons";
import Editorial from "../components/EditorialModal";
import DistributorsStatusModal from "../components/DistributorsStatusModal";
import DistributorsNewswireModal from "../components/DistributorsNewswireModal";
import EventEmitter from "../lib/events";
import Notifications from "../components/Notifications";
import { type } from "os";
import Delete from "../components/Delete";

const NewswirePage = () => {
	// states
	const [fulldata, setFulldata] = useState([]);
	const [data, setData] = useState([]);
	const [editorialModalOpen, setEditorialModalOpen] = useState(false);
	const [rowData, setRowData] = useState();
	const [loading, setLoading] = useState<boolean>(false);
	const [distributorsStatusModal, setDistributorsStatusModal] =
		useState<boolean>(false);
	const [distributionRecord, setDistributionRecord] = useState<any>(null);
	const [distributorsModal, setDistributorsModal] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
	const [filtered, setFiltered] = useState(false);

	// data source for newswire table
	useEffect(() => {
		fetchData();
		console.log("fulldata", fulldata);
	}, []);

	// useEffect(() => {
	// 	console.log("selected row is empty", selectedRowKeys);
	// 	fetchData();
	// }, [selectedRowKeys.length === 0]);

	useEffect(() => {
		console.log("selected rows:", selectedRowKeys);
	}, [selectedRowKeys]);

	useEffect(() => {
		console.log("data", data);
	}, [data]);

	useEffect(() => {
		if (editorialModalOpen == false) {
			fetchData();
		}
	}, [editorialModalOpen]);

	useEffect(() => {
		// listen to distribute_articles event
		EventEmitter.on("RELOAD_NEWSWIRE_PAGE", () => {
			console.log("reload data");
			fetchData();
		});

		return () => {
			EventEmitter.off("RELOAD_NEWSWIRE_PAGE", () => {});
		};
	}, []);

	const fetchData = async () => {
		setLoading(true);
		const response = await NewswireController.getNewswires();
		const data = response.data.map((d: any) => {
			return {
				key: d._id,
				url: d.url,
				receive_date: d.date,
				title: d.title,
				sender: d.sender,
				mailbox: d.mailbox,
				status: d.status,
				contents: d.contents,
				distribution_status: d.distribution_status,
			};
		});
		setFulldata(data);
		setData(data);
		setLoading(false);
	};

	const handleDisClick = (record: any) => {
		console.log(`Clicked 'dis' for item with id: ${record.key}`);
		console.log(record);
		setDistributionRecord(record);
		setDistributorsStatusModal(true);
	};

	// columns for newswire table
	const columns = [
		{
			title: "Received Date",
			dataIndex: "receive_date",
			key: "receive_date",
			sorter: (a: any, b: any) => {
				return a["receive_date"]
					.toString()
					.localeCompare(b["receive_date"].toString());
			},
			render: (date: any) => {
				if (date) {
					const _date = new Date(date);
					const options: any = {
						day: "2-digit",
						month: "2-digit",
						year: "numeric",
						hour: "2-digit",
						minute: "2-digit",
						second: "2-digit",
					};
					var formatter = new Intl.DateTimeFormat("en-GB", options);
					var formattedDate = formatter.format(_date);
					formattedDate = formattedDate
						.replace("am", "AM")
						.replace("pm", "PM");
					return formattedDate;
					// return _date.toLocaleString("en-US", {
					// 	day: "2-digit",
					// 	month: "2-digit",
					// 	year: "numeric",
					// 	hour: "numeric",
					// 	minute: "numeric",
					// 	hour12: true,
					// });
				}
				return "N/A";
			},
			enableSearch: true,
			searchType: "date",
			width: "20%",
			align: "center",
		},
		{
			title: "Story Title",
			dataIndex: "title",
			key: "title",
			sorter: (a: any, b: any) => {
				return a.title.localeCompare(b.title);
			},
			render: (text: any, record: any) => {
				return (
					<Space align="center">
						<Button
							style={{
								color: "#4A97DE",
								textAlign: "left",
							}}
							type="text"
							onMouseEnter={(e) =>
								(e.currentTarget.style.color = "#BBC6D1")
							}
							onMouseLeave={(e) =>
								(e.currentTarget.style.color = "#4A97DE")
							}
							onClick={() => handleEditorialConfigModal(record)}
						>
							<EditOutlined />
						</Button>
						{text}
					</Space>
				);
			},
			enableSearch: true,
			searchType: "select",
		},
		{
			title: "Mailbox",
			dataIndex: "mailbox",
			key: "mailbox",
			render: (name: any) =>
				name ? name.charAt(0).toUpperCase() + name.slice(1) : "N/A",
			enableSearch: true,
			searchType: "select",
			width: "20%",
			align: "center",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status: any, record: any) => (
				<>
					{status.map((s: any) => {
						let color = s === "DB" ? "geekblue" : "cyan";
						if (s === "DIS") {
							color = "green";
							return (
								<Tag
									color={color}
									key={s}
									onClick={() => handleDisClick(record)}
									style={{ cursor: "pointer" }}
								>
									{s.toUpperCase()}
								</Tag>
							);
						}
						return (
							<Tag
								color={s.includes("!") ? "red" : color}
								key={s}
							>
								{s.toUpperCase()}
							</Tag>
						);
					})}
				</>
			),
			width: "20%",
			enableSearch: true,
			searchType: "checkbox",
			align: "center",
		},
	];
	const { transformedColumns, filteredData } = TransformedColumns(
		columns,
		fulldata
	);

	useEffect(() => {
		filteredData && setData(filteredData);
		setFiltered(true);
	}, [filteredData]);

	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			console.log(
				`selectedRowKeys: ${selectedRowKeys}`,
				"selectedRows: ",
				selectedRows
			);
			setSelectedRowKeys(selectedRowKeys);
		},
	};

	const handleEditorialConfigModal = (rec: any) => {
		setEditorialModalOpen(true);
		setRowData(rec);
	};

	const handleEditorialCancel = () => {
		setEditorialModalOpen(false);
	};

	const clearSelectedKeys = () => {
		setLoading(true);
		setTimeout(() => {
			setSelectedRowKeys([]);
			setLoading(false);
		}, 1000);
	};

	// Delete newswires function
	const deleteNewswires = async () => {
		setLoading(true);
		NewswireController.deleteNewswire(selectedRowKeys)
			.then(async (res: any) => {
				console.log(res);
				await fetchData();
				if (res.deletedCount > 0) {
					notification.success({
						message: "Delete Success",
						description: `${res.deletedCount} items deleted.`,
					});
				} else {
					notification.error({
						message: "Delete Failed",
						description: "No items deleted.",
					});
				}
			})
			.catch((e: any) => {
				console.log(e);
				notification.error({
					message: "Delete Failed",
					description: "No items deleted.",
				});
			});
		setSelectedRowKeys([]);
	};

	return (
		<>
			{editorialModalOpen && (
				<Editorial
					isOpen={editorialModalOpen}
					data={rowData}
					open={editorialModalOpen}
					handleCancel={handleEditorialCancel}
					handleOk={() => setEditorialModalOpen(false)}
				/>
			)}
			{distributorsStatusModal && (
				<DistributorsStatusModal
					open={distributorsStatusModal}
					onCancel={() => setDistributorsStatusModal(false)}
					articleRecord={distributionRecord}
				/>
			)}
			{distributorsModal && (
				<DistributorsNewswireModal
					visible={distributorsModal}
					articles_id={selectedRowKeys}
					onClose={() => {
						setDistributorsModal(false);
						clearSelectedKeys(); // clear selection after distributing
					}}
				/>
			)}
			<div>
				<h1>Newswire</h1>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						padding: "10px",
					}}
				>
					<Button
						className="button-green"
						onClick={() => {
							console.log("Send ARR_FETCH_NEWSWIRE signal.");
							Notifications.show(
								"info",
								"Fetching Newswire",
								"Please wait for the process to finish."
							);
							EventEmitter.emit("ARR_FETCH_NEWSWIRE");
						}}
					>
						Fetch Newswire
					</Button>
				</div>
				<div
					style={{
						display: "flex",
						marginBottom: "10px",
						justifyContent:
							selectedRowKeys.length > 0
								? "space-between"
								: "flex-end",
					}}
				>
					<Button
						className="button-green"
						onClick={() => setDistributorsModal(true)}
						style={
							selectedRowKeys.length > 0
								? { display: "block" }
								: { display: "none" }
						}
					>
						Distribute
					</Button>
					<div
						style={{
							display: "flex",
							gap: "10px",
						}}
					>
						<Button
							className="button-green"
							onClick={() => {
								setFiltered(false);
								setData(fulldata);
								setSelectedRowKeys([]);
							}}
							style={
								filtered === true
									? { display: "block" }
									: { display: "none" }
							}
						>
							Reset
						</Button>
						{sessionStorage.getItem("role") == "admin" && (
							// Delete function only for Admin
							<Delete
								title={"Delete Newswire"}
								message={
									"Are you sure you want to delete? This action is irreversible and will permanently remove the item."
								}
								selectedRowKeys={selectedRowKeys}
								handleDelete={deleteNewswires}
							/>
						)}
					</div>
				</div>
				<NewswireTable
					loading={loading}
					dataSource={data}
					columns={transformedColumns}
					rowSelection={rowSelection}
					pagination={{
						position: ["bottomRight"],
						defaultPageSize: 50,
						showSizeChanger: true,
						pageSizeOptions: ["10", "20", "50", "100"],
					}}
				/>
			</div>
		</>
	);
};

export default NewswirePage;
